.herobg{
    background: url('./assets/images/1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
}
.{
    background: url('./assets/images/2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.adapt-bg{
    background: url('./assets/images/3.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.visual-bg{
    background: url('./assets/images/4.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
}


.text-set{
    text-shadow: 0px 4px 24px rgba(0, 0, 0, 0.40);
}
.box-set2{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(160, 175, 219, 0.50);
    background: rgba(13, 20, 44, 0.62);
    backdrop-filter: blur(0.5px);
    z-index: 1;

}

.box-set3{
border-radius: 18px;
border: 1px solid rgba(160, 175, 219, 0.50);
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(5px);

}

.set-box{
    background: linear-gradient(180deg, #CAD5F4 20%, #FFF 100%);
    box-shadow: 0px 11px 20px 1px rgba(0, 0, 0, 0.19);


}

/* .adapt-bg{
    background: rgba(166, 164, 255, 0.10);


} */

.adapt-set{
    border-radius: 16px;
border: 1px solid #646299;

background: rgba(17, 25, 54, 0.74);

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(2px);
}

.model-set{
    border-radius: 18px;
border: 1px solid rgba(160, 175, 219, 0.50);

background: #060A1A;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(5px);
}

.learning-set{
    border-radius: 18px;
border: 1px solid rgba(160, 175, 219, 0.50);

background: rgba(13, 20, 44, 0.80);

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(5px);
}

.visual-set{
    border-radius: 18px;
border: 1px solid rgba(160, 175, 219, 0.50);

background: #060A1A;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(5px);
}



@media screen and (max-width: 1440px) {
    .visual-bg{
        background: #080D23;
        
    }
  }


