@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
/* Extend your CSS with styles for all required elements */
.privacyPolicyContent h1 {
  font-size: 2em; /* Example size */
  color: #333; /* Example color */
  margin-bottom: 0.5em;
}

.privacyPolicyContent h2 {
  font-size: 1.75em;
  color: #666;
  margin-bottom: 0.5em;
}

.privacyPolicyContent h3 {
  font-size: 1.0em;
  color: #999;
  margin-bottom: 0.5em;
}

.privacyPolicyContent h4 {
  font-size: 1.0em;
  font-weight: bold;
  color: #ccc;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacyPolicyContent p {
  font-size: 1em;
  color: #ddd; /* Lighter text color for contrast */
  margin-bottom: 10px;
}

.privacyPolicyContent ul {
  list-style-type: disc; /* Example list style */
  margin-left: 20px; /* Indent list */
}

.privacyPolicyContent li {
  margin-bottom: 5px; /* Space between list items */
}

/* You can add more styles for other elements as needed */
body {
  margin: 0;
  padding: 0;
background: #111936;
}

@font-face {
  font-family: AzeretMono-Regular;
  src: url("./assets/fontfamily/AzeretMono-Regular.ttf");
}

@font-face {
  font-family: AzeretMono-SemiBold;
  src: url("./assets/fontfamily/AzeretMono-SemiBold.ttf");
}

@font-face {
  font-family: InriaSans-Bold;
  src: url("./assets/fontfamily/InriaSans-Bold.ttf");
}

@font-face {
  font-family: NovaFlat-Regular;
  src: url("./assets/fontfamily/NovaFlat-Regular.ttf");
}



.f-f-r-az{
  font-family: AzeretMono-Regular;
}

.f-f-sm-az{
  font-family: AzeretMono-SemiBold;
}

.f-f-b-in{
  font-family: InriaSans-Bold;
}

.f-f-r-no{
  font-family: NovaFlat-Regular;
}

/* .background-main{
  background-image: url("./assets/images/bodybackgroubd.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
} */